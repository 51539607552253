<template lang="pug">
    section#organogramaOrganizacional
        .wrapper
            #orgchart(ref="orgchart")
</template>

<script>
import { props } from '@/mixins/components'

export default {
  name: 'section-organograma-organizacional',
  props,
  data () {
    return {
      chart: null,
      content: [
        ['CONSELHO ADMINISTRATIVO', ''],
        ['PRESIDÊNCIA', 'CONSELHO ADMINISTRATIVO'],
        ['CONCITIBA', 'PRESIDÊNCIA'],
        ['UTAG', 'PRESIDÊNCIA'],
        ['SECRETARIA&nbsp;EXECUTIVA', 'PRESIDÊNCIA'],
        ['DIRETORIA', 'SECRETARIA&nbsp;EXECUTIVA'],
        ['SECRETARIA EXECUTIVA', 'CONCITIBA'],
        ['DIRETORIA ADMINISTRATIVA E FINANCEIRA', 'DIRETORIA'],
        ['DIRETORIA DE INFORMAÇÕES', 'DIRETORIA'],
        ['DIRETORIA DE PLANEJAMENTO', 'DIRETORIA'],
        ['DIRETORIA DE PROJETOS', 'DIRETORIA'],
        ['DIRETORIA DE IMPLANTAÇÃO', 'DIRETORIA'],
        ['ASSESSORIA', 'PRESIDÊNCIA'],
        ['ASSESSORIA DE COMUNICAÇÃO', 'ASSESSORIA'],
        ['ASSESSORIA JURIDICA', 'ASSESSORIA'],
        ['ASSESSORIA TÉCNICA', 'ASSESSORIA']
      ]
    }
  },
  mounted () {
    google.charts.load('current', {
      packages: ['orgchart'],
      callback: () => {
        this.drawOrgchart()
      }
    })
  },
  methods: {
    drawOrgchart () {
      // eslint-disable-next-line
            let data = new google.visualization.DataTable()
      data.addColumn('string', 'Nível')
      data.addColumn('string', 'Superior')

      data.addRows(this.content)

      this.chart = new google.visualization.OrgChart(this.$refs.orgchart)
      this.chart.draw(data, { allowHtml: true })
    }
  }
}
</script>

<style lang="stylus" scoped src="./OrganogramaOrganizacional.styl"></style>
